export const LaunchPasswordPrompt = (): boolean => {
  const pw = window.prompt('Please enter the password to access documents.');

  if (pw === 'LanginoLaw2024') {
    return true;
  } else {
    window.alert(
      'Incorrect password. Please contact Langino Law for access to these documents.'
    );
    return false;
  }
};
