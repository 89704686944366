import React, { useState } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import DocumentCardCmp from '../components/DocumentCardCmp';

const DocumentsPage = () => {
  const [localAuth, setLocalAuth] = useState(false);

  return (
    <Layout>
      <Seo title='Langino Law Document Bank' />

      <div className='max-w-6xl p-2 mt-4 sm:m-auto sm:p-12'>
        {/* Intro Section */}
        <div className='pb-10 border-b-2'>
          <div className='flex flex-col-reverse justify-center md:flex-row'>
            <div className='flex flex-col w-full p-5 md:p-10 md:text-base md:w-3/5 lg:text-xl'>
              <p className='base-text-color'>
                Attorneys often ask me to help them with their cases. Local and
                national organizations invite me to teach other lawyers about
                various topics in the fields of product liability, catastrophic
                injury, and wrongful death. Many of my thoughts about the legal
                profession are posted <Link to='/articles'>here</Link>. In the
                document bank below, you can also find some of my actual legal
                work, e.g. pleadings, motions, and discovery, that I use in my
                practice. Please feel free to
                <Link to='/contact'> contact me</Link> if you're an attorney or
                need legal advice.
              </p>
              <p className='mt-4 text-lg base-text-color'>Sincerely,</p>
              <div className='adam-signature'>
                <img
                  alt='Adam Langino signature'
                  className='rounded-lg'
                  src={`/images/adam/langino-signature.png`}
                />
              </div>
            </div>

            <div className='flex-row justify-center w-full p-5 md:p-10 md:w-2/5 lg:w-1/2'>
              <img
                alt='Adam Langino'
                className='rounded-lg'
                src={`/images/adam/langino-documents.jpg`}
              />
            </div>
          </div>
        </div>
        {/* End Intro */}
        <div className='max-w-6xl p-2 mt-4 sm:m-auto sm:p-12'>
          <div className='pt-2 pb-6 mx-auto mt-2 border-b-4 sm:block'>
            <h2 className='py-4 text-2xl text-center font-slim text-primary'>
              Document Bank
            </h2>
            {/* Document Section */}
            <div className='grid grid-cols-1 gap-4 px-6 my-4 space-evenly md:grid-cols-3 sm:w-auto'>
              <DocumentCardCmp
                title='Adaptive Equipment Failures'
                description=''
                folder='/files/Adaptive_Equipment_Failures-10-31-23.zip'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
              <DocumentCardCmp
                title='Cell Phone Explosions'
                description=''
                folder='/files/Cell_Phone_Explosions-10-31-23.zip'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
              <DocumentCardCmp
                title='Child Seat Failures'
                description=''
                folder='/files/Child_Seat_Failures-10-31-23.zip'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
              <DocumentCardCmp
                title='Elevator Service Failures'
                description=''
                folder='/files/Elevator_Defects-10-31-23.zip'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
              <DocumentCardCmp
                title='Swimming Pool Drownings'
                description=''
                folder='/files/Drowning-10-31-23.zip'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
              <DocumentCardCmp
                title='Parking Lot Attacks'
                description=''
                folder='/files/Parking_Lot_Security-10-31-23'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
              <DocumentCardCmp
                title='Product Failures'
                description=''
                folder='/files/Product_Failures-10-31-23.zip'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
              <DocumentCardCmp
                title='Rehabilitation Program Failures'
                description=''
                folder='/files/Rehabilitation_Programs-10-31-23.zip'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
              <DocumentCardCmp
                title='Truck Wrecks'
                description=''
                folder='/files/Truck_Collisions-10-31-23.zip'
                localAuth={localAuth}
                setLocalAuth={setLocalAuth}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DocumentsPage;
